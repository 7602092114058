<template lang="html">
  <div>
    <span v-for="(token, index) in tokens" :key="index" :class="[token.type]">
      {{ token.string }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  computed: {
    tokens () {
      const regularText = this.value.split(/[;.,]/)
      let index = 0
      const tokens = []
      for (const fragment of regularText) {
        index += fragment.length
        tokens.push({type: 'text', string: fragment})
        if (index <= this.value.length) {
          tokens.push({type: 'punctuation', string: this.value[index]})
          index++
        }
      }
      return tokens
    }
  }
}
</script>

<style lang="scss" scoped>
.punctuation {
  color: red;
  font-weight: bold;
}
</style>

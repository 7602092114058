<template lang="html">
  <div class="order-result-input-layout" :style="gridStyle">
    <slot />
    <template v-if="$slots.summary">
      <slot name="summary" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    headerColumnNames: {
      defatult: () => 2,
      type: Array
    }
  },
  computed: {
    gridStyle () {
      return `grid-template-columns: repeat(${this.headerColumnNames.length}, 1fr)`
    }
  }
}
</script>

<style lang="scss" scoped>
.order-result-input-layout {
  display: grid;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  &:not(:first-child) {
    margin-top: 1em;
  }
}
</style>

<template lang="html">
  <VueSelectize
    v-model="value"
    :options="columnOptions"
    :required="required"
    allow-empty
    :name="columnId"
  >
    <slot slot="label" />
  </VueSelectize>
</template>

<script>
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  props: {
    columnId: String,
    csvHeaders: Array,
    required: Boolean
  },
  data () {
    return {
      column: null
    }
  },
  computed: {
    columnOptions () {
      return this.csvHeaders.map(text => ({text, value: text.toUpperCase()}))
    },
    value: {
      get () {
        return this.column
      },
      set (option) {
        this.column = option !== null ? option.value : null
        this.$emit('update', {id: this.columnId, column: this.column})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template lang="html">
  <BsModal :value="value" @input="$emit('input', $event)">
    <template #title>
      <IxRes>bf.resultImport.title.ImportOrderResult</IxRes>
    </template>
    <div class="selected-order-container">
      <label class="order-label">
        <IxRes>bf.resultImport.SelectedOrder</IxRes>
      </label>
      {{ orderName }}
    </div>
    <template v-if="!csvIsAccepted">
      <CsvDropArea v-if="order" @load="csvLinesRaw" />
      <template v-else>
        <div class="alert alert-info">
          <IxRes>bf.resultImport.NoExistingOrdersInfo</IxRes>
        </div>
      </template>
    </template>
    <template v-else>
      <ImportDropdowns :headers="csvHeaders" @update="updateMapping" />
      <HelpBox>
        <span v-for="line in columnPreview" :key="line">
          {{ line }}
        </span>
      </HelpBox>
      <div class="button-container">
        <IxButton
          large upload
          rounded
          :disabled="!columnMapping.sampleId"
          @click="$emit('upload', {columns: columnMapping, data: parsedCsvData, isSemicolonSeparated})"
        >
          <IxRes>phbb.probingResults.buttons.import</IxRes>
        </IxButton>
        <IxButton
          large cancel
          rounded
          @click="$emit('cancel')"
        />
      </div>
    </template>
  </BsModal>
</template>

<script>
import BsModal from '@components/bootstrap/BsModal'

import CsvDropArea from './widgets/CsvDropArea'
import {toObject} from '@helpers/reducers'
import ImportDropdowns from '@frs/components/phbb/probing-results/widgets/importDropdowns'
import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton,
    HelpBox,
    ImportDropdowns,
    CsvDropArea,
    BsModal
  },
  props: {
    value: Boolean,
    order: Object
  },
  data () {
    return {
      csvIsAccepted: false,
      separator: null,
      isSemicolonSeparated: false,
      fileContent: [],
      columnMapping: {
        sampleId: null,
        ph: null,
        sand: null,
        silt: null,
        clay: null,
        humus: null
      }
    }
  },
  computed: {
    orderName () {
      return this.order ? this.order.name : ''
    },
    csvLines () {
      const processLine = line => line.split(this.separator).map(x => x.trim())
      return this.fileContent.map(processLine)
    },
    csvHeaders () {
      return this.csvLines ? this.csvLines[0] : []
    },
    csvData () {
      return this.csvLines.slice(1)
    },
    parsedCsvData () {
      const stringToUpperCase = (value) => {
        return typeof value === 'string' ? value.toUpperCase() : value
      }

      const transformRow = row => row
          .map((x, i) => [stringToUpperCase(this.csvHeaders[i]), x])
          .reduce(toObject, {})

      return this.csvData.map(transformRow)
    },
    columnPreview () {
      if (!this.parsedCsvData.length) return ''

      const firstLine = this.parsedCsvData[0]
      return [
        `Probennummer: ${firstLine[this.columnMapping.sampleId] ? firstLine[this.columnMapping.sampleId] : '?'}`,
        `pH: ${firstLine[this.columnMapping.ph] ? firstLine[this.columnMapping.ph] : '?'}`,
        `Sand: ${firstLine[this.columnMapping.sand] ? firstLine[this.columnMapping.sand] + '%' : '?'}`,
        `Schluff: ${firstLine[this.columnMapping.silt] ? firstLine[this.columnMapping.silt] + '%' : '?'}`,
        `Ton: ${firstLine[this.columnMapping.clay] ? firstLine[this.columnMapping.clay] + '%' : '?'}`,
        `Humus: ${firstLine[this.columnMapping.humus] ? firstLine[this.columnMapping.humus] + '%' : '?'}`
      ]
    }
  },
  methods: {
    csvLinesRaw ({csv, separator}) {
      this.isSemicolonSeparated = separator
      this.separator = separator ? ';' : ','
      this.csvIsAccepted = true
      this.fileContent = csv
        ? csv.split(/[\r\n]+/).filter(x => x)
        : []
    },
    setDefaultData () {
      this.csvIsAccepted = false
      this.separator = null
      this.isSemicolonSeparated = false
      this.fileContent = []
      this.columnMapping = {
        sampleId: null,
        ph: null,
        sand: null,
        silt: null,
        clay: null,
        humus: null
      }
    },
    updateMapping ({id, column}) {
      this.columnMapping[id] = column
    }
  },
  watch: {
    value (val) {
      if (val === false) {
        this.setDefaultData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: space-between;
}
</style>

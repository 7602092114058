import {readAsText} from '@helpers/files'

// column definition could be injected somewhere to support different formats
const columnDefinition = [
  {headerName: 'BP_NR', propName: 'sampleId'},
  {headerName: 'PH', propName: 'ph'},
  {headerName: 'PHSTUFE', propName: 'ph_contentClass'},
  {headerName: 'P in mg/100g', propName: 'phosphorus'},
  {headerName: 'PSTUFE', propName: 'phosphorus_contentClass'},
  {headerName: 'K in mg/100g', propName: 'potassium'},
  {headerName: 'KSTUFE', propName: 'potassium_contentClass'},
  {headerName: 'MG in mg/100g', propName: 'magnesium'},
  {headerName: 'MGSTUFE', propName: 'magnesium_contentClass'}
]

export const canConvert = files => {
  return files.length === 1 && files[0].name.endsWith('.csv')
}

export const convert = (files, order) => {
  return readAsText(files[0])
  .then(csv => {
    return toImportStruct(processData(csv), order)
  })
}

const toImportStruct = (processData, order) => {
  let nutrients = []
  var matchId = function (id) {
    return order.geometries.find(geometry => id === geometry.sampleId).geometry
  }
  var struct = function (nutrient, measurement, contentClass, timestamp, geometry) {
    return {nutrient, measurement, contentClass, timestamp, geometry}
  }

  for (const nutrient of Object.values(processData)) {
    nutrients.push(struct('ph', nutrient['ph'], nutrient['ph_contentClass'], order.created, matchId(nutrient['sampleId'])))
    nutrients.push(struct('magnesium', nutrient['magnesium'], nutrient['magnesium_contentClass'], order.created, matchId(nutrient['sampleId'])))
    nutrients.push(struct('phosphorus', nutrient['phosphorus'], nutrient['phosphorus_contentClass'], order.created, matchId(nutrient['sampleId'])))
    nutrients.push(struct('potassium', nutrient['potassium'], nutrient['potassium_contentClass'], order.created, matchId(nutrient['sampleId'])))
  }
  return nutrients
}

const processData = (csv) => {
  const all = csv.split(/\r\n|\n/)
  const headerCols = all[0].split(';')
  const descriptors = columnDefinition.map(x => {
    return Object.assign(x, {index: headerCols.indexOf(x.headerName)})
  })

  all.shift() // remove header line
  const converted = all.filter(x => x !== '').map(row => {
    return convertRow(descriptors, row.split(';'))
  })
  return converted.reduce(toLookup, {})
}

const convertRow = (descriptors, row) => {
  return descriptors.reduce((acc, desc) => {
    return Object.assign(acc, {[desc.propName]: row[desc.index]})
  }, {})
}

const toLookup = (acc, curr) => {
  return Object.assign(acc, {[curr.sampleId]: curr})
}

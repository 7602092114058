<template lang="html">
  <div>
    <div class="row">
      <div class="col-md-4">
        <ColumnMappingDropdown
          column-id="sampleId" required
          :csv-headers="headers"
          @update="$emit('update', $event)"
        >
          <IxRes>bf.resultImport.columns.sampleId</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="ph" :csv-headers="headers" @update="$emit('update', $event)">
          <IxRes>bf.resultImport.columns.ph</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="sand" :csv-headers="headers" @update="$emit('update', $event)">
          <IxRes>bf.resultImport.columns.sand</IxRes>
        </ColumnMappingDropdown>
      </div>
    </div>
    <div class="row flex">
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="silt" :csv-headers="headers" @update="$emit('update', $event)">
          <IxRes>bf.resultImport.columns.silt</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="clay" :csv-headers="headers" @update="$emit('update', $event)">
          <IxRes>bf.resultImport.columns.clay</IxRes>
        </ColumnMappingDropdown>
      </div>
      <div class="col-md-4">
        <ColumnMappingDropdown column-id="humus" :csv-headers="headers" @update="$emit('update', $event)">
          <IxRes>bf.resultImport.columns.humus</IxRes>
        </ColumnMappingDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnMappingDropdown from './ColumnMappingDropdown'

export default {
  components: {
    ColumnMappingDropdown
  },
  props: {
    headers: Array
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display:flex;
}
.flex-align-center {
  align-self: center;
}
</style>

import translate from 'src/js/i18n/translate'

export const minLength = (value) => {
  const rule = x => x.length >= value
  rule.messages = {
    error: translate('Common.SR_Common.RuleMinLengthError', {value}),
    description: translate('Common.SR_Common.RuleMinLengthDescription', {value})
  }

  return rule
}

export const maxLength = (value) => {
  const rule = x => x.length <= value
  rule.messages = {
    error: translate('Common.SR_Common.RuleMaxLengthError', {value}),
    description: translate('Common.SR_Common.RuleMaxLengthDescription', {value})
  }

  return rule
}

export const betweenLength = (min, max) => {
  const rule = x => x.length <= max && x.length >= min
  rule.messages = {
    error: translate('Common.SR_Common.RuleBetweenLengthError', {min, max}),
    description: translate('Common.SR_Common.RuleBetweenLengthDescription', {min, max})
  }

  return rule
}

export const min = (value) => {
  const rule = x => value === null || value === undefined || x >= value
  rule.messages = {
    error: translate('Common.SR_Common.RuleMinError', {value}),
    description: translate('Common.SR_Common.RuleMinDescription', {value})
  }

  return rule
}

export const max = (value) => {
  const rule = x => value === null || value === undefined || x <= value
  rule.messages = {
    error: translate('Common.SR_Common.RuleMaxError', {value}),
    description: translate('Common.SR_Common.RuleMaxDescription', {value})
  }

  return rule
}

export const between = (min, max) => {
  const rule = x => (max === null || max === undefined || x <= max) && (min === null || min === undefined || x >= min)
  rule.messages = {
    error: translate('Common.SR_Common.RuleBetweenError', {min, max}),
    description: translate('Common.SR_Common.RuleBetweenDescription', {min, max})
  }

  return rule
}

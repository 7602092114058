<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons'

import NumericInput from '@components/forms/NumericInput'

export default {
  functional: true,
  render (h, context) {
    const {locked, ...props} = context.props

    return h('div', {
      class: 'order-result-numeric-input'
    }, [h(NumericInput, {
      ...context.data,
      props: {
        ...props,
        disabled: locked,
        hideDescription: true
      },
      scopedSlots: {
        ...context.data.scopedSlots,
        icon: () => locked
          ? h(FontAwesomeIcon, {
            props: {
              icon: faLock
            },
            class: 'lock-icon'
          })
          : [],
        description: () => {
          h('span', {
            ...props.rule.message.description
          })
        }
      }
    }, context.children)])
  }
}
</script>

<style lang="scss">
.order-result-numeric-input {
  input.form-control {
    height: 24px;
    font-size: 14px;
    padding: 2px 4px;
    max-width: 60px;
  }

  .has-feedback .form-control {
    padding-right: 4px;
  }

  span.input-group-addon {
    padding: 2px 4px;
    border: none;
    background-color: transparent;
  }

  span.help-block {
    display: none;
  }

  .lock-icon {
    color: gray;
  }
}
</style>

<script>

import translate from 'src/js/i18n/translate'

export default {
  functional: true,
  props: {
    headerColumnNames: {
      type: Array
    },
    inputMode: {
      type: String,
      validate: (value) => ['humus', 'corg'].indexOf(value) !== -1
    }
  },
  render (h, context) {
    const CellWrapper = content => h('div', {
      class: 'cell-wrapper'
    }, content)

    const {
      headerColumnNames, inputMode
    } = context.props

    return headerColumnNames.map(name => {
      if (name === 'humusDisplay') {
        name = inputMode === 'humus' ? 'humus' : 'corg'
      }

      const translated = translate(`phbb.probingResults.table.header.${name.toLowerCase()}`)
      return CellWrapper([h('span', translated)])
    }).filter(x => x)
  }
}
</script>

<style lang="scss" scoped>
.cell-wrapper {
  padding: 0.425em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;

  &:first-child {
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
  }

  // &:nth-of-type(6n+1) {
  //   justify-content: start;
  // }

  // &:nth-of-type(8n + 1), &:nth-of-type(8n + 3), &:nth-of-type(8n + 5), &:nth-of-type(8n + 7) {
  //   background-color: rgba(#000000, 4%);
  // }
}
</style>

<template>
  <div class="probing-result-container">
    <FrsRouterBackLink target="phbb">
      <template #text>
        <IxRes>phbb.buttons.backToPhbb</IxRes>
      </template>
    </FrsRouterBackLink>
    <div class="probing-result">
      <h3>
        <IxRes>phbb.probingResults.labels.orderSelection</IxRes>
      </h3>

      <HelpBox type="info">
        <IxRes>
          phbb.probingResults.info.incompleteOrders
        </IxRes>
      </HelpBox>

      <div class="order-selection">
        <VueSelectize
          v-model="selectedOrder" allow-empty
          class="order-select"
          :options="availableOrders"
          :fields="orderFields"
          name="orderSelection"
        />
        <IxButton
          large rounded
          upload
          class="import-button"
          @click="showModal = !showModal"
        >
          Ergebnisse importieren
        </IxButton>
      </div>
      <div v-if="selectedOrder" class="result">
        <hr>
        <HelpBox>
          <IxRes>phbb.probingResults.descriptions.probingMethod</IxRes>
        </HelpBox>
        <div class="result-header">
          <VueDateTimePicker
            v-model="date" format="L"
            class="header-date"
            required
            iso name="timestamp"
          >
            <template #label>
              <IxRes>phbb.probingResults.labels.dateTime</IxRes>
            </template>
          </VueDateTimePicker>
          <TextInput
            v-model="lab" class="header-lab"
            required name="lab"
          >
            <template #label>
              <IxRes>phbb.probingResults.labels.lab</IxRes>
            </template>
          </TextInput>
          <VueSelectize
            v-model="probingMethod"
            required class="header-method"
            :options="['CAL', 'DL', ' CaCl2', 'Elementaranalyse oder Glühverlust', 'Sieb- und Sedimentationsnalyse (DIN ISO 11277)']"
            name="probingMethod"
          >
            <template #label>
              <IxRes>phbb.probingResults.labels.probingMethod</IxRes>
            </template>
          </VueSelectize>
        </div>
        <hr>

        <BsRadioList
          v-if="selectedOrderData && selectedOrderData.targetValue === 'humus'"
          v-model="inputMode" :options="['humus', 'corg']"
          compact name="inputMode"
        >
          <template #default="{option}">
            <IxRes>phbb.probingResults.labels.{{ option === 'humus' ? 'humus' : 'corg' }}</IxRes>
          </template>
        </BsRadioList>

        <template v-if="preparedData">
          <OrderResultInputGrid :header-column-names="allowedDisplayFields">
            <OrderResultHeaderLine :header-column-names="allowedDisplayFields" :input-mode="inputMode" />
            <OrderResultInputLine
              v-for="data in preparedData" :key="data.sampleId"
              :data="data" :input-mode="inputMode"
              @click="selectedSamplePointId = $event"
              @input="update($event, data.sampleId)"
            />
          </OrderResultInputGrid>

          <HelpBox type="info">
            <IxRes>
              phbb.probingResults.info.autoCompleteOrder
            </IxRes>
          </HelpBox>
          <HelpBox type="warning">
            <IxRes>
              phbb.probingResults.warning.nullValues
            </IxRes>
          </HelpBox>

          <div>
            <IxButton save large @click="saveOrder()">
              Ergebnisse speichern
            </IxButton>
          </div>
        </template>
      </div>
    </div>

    <ProbingPointsLayer
      :selected-feature="[selectedFeature]"
      :features="pointFeatures"
      :field-feature="[]"
    />

    <FrsLoadingIndicator :requests="['phbb.order-result.orders', 'phbb.order-result.details', 'phbb.order-result.save']" />

    <ResultImportModal
      v-model="showModal" :order="selectedOrder"
      @upload="importData" @cancel="showModal = false"
    />
  </div>
</template>

<script>
import {keyBy, pick, orderBy, flatten} from 'lodash'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'

import {toObject} from '@helpers/reducers'
import {notifications} from 'src/js/infrastructure'
import FormPartMixin from '@components/forms/FormPartMixin'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

import BsRadioList from '@components/bootstrap/BsRadioList'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import TextInput from '@components/forms/TextInput'
import HelpBox from '@components/help/HelpBox'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import ResultImportModal from '@frs/components/phbb/probing-results/ResultImportModal'
import OrderResultInputGrid from '@frs/components/phbb/probing-results/widgets/OrderResultInputGrid'
import OrderResultInputLine from '@frs/components/phbb/probing-results/widgets/OrderResultInputLine'
import OrderResultHeaderLine from '@frs/components/phbb/probing-results/widgets/OrderResultHeaderLine'
import ProbingPointsLayer from '@frs/components/phbb/widgets/ProbingPointsLayer'

export default {
  components: {
    FrsLoadingIndicator,
    BsRadioList,
    ProbingPointsLayer,
    OrderResultHeaderLine,
    OrderResultInputLine,
    OrderResultInputGrid,
    TextInput,
    HelpBox,
    IxButton,
    VueDateTimePicker,
    ResultImportModal,
    FrsRouterBackLink,
    VueSelectize
  },
  mixins: [
    DisableDefaultMapInteractionsMixin,
    FormPartMixin
  ],
  data () {
    return {
      availableOrders: [],
      orderDataLookup: null,

      selectedOrder: null,
      selectedSampleId: null,

      inputMode: 'humus',
      showModal: false,
      date: null,
      lab: null,
      probingMethod: 'DL'
    }
  },
  computed: {
    orderLookup () {
      return keyBy(this.availableOrders, 'id')
    },
    orderFields () {
      return {
        text: order => order.name,
        value: order => order.id,
        label: order => this.$i18n.format(order.created, 'date')
      }
    },
    selectedOrderData () {
      if (!this.selectedOrder || !this.orderDataLookup) return
      if (!this.orderDataLookup[this.selectedOrder.id]) return

      return this.orderDataLookup[this.selectedOrder.id]
    },
    pointFeatures () {
      if (!this.selectedOrderData) return []
      return this.orderDataLookup[this.selectedOrder.id].features
    },
    editablePointProperties () {
      if (!this.selectedOrderData) return []
      return this.orderDataLookup[this.selectedOrder.id].features.map(x => x.properties)
    },
    selectedFeature () {
      return this.selectedSamplePointId ? this.pointFeatures.find(feature => feature.properties.sampleId === this.selectedSampleId) : null
    },
    allowedDataFields () {
      if (!this.selectedOrderData) return []

      const targetValue = this.orderDataLookup[this.selectedOrder.id].targetValue
      switch (targetValue) {
      case 'ph':
        return ['ph']
      case 'humus':
        return ['humus']
      case 'soilTexture':
        return ['sand', 'silt', 'clay']
      default:
        throw Error(`target value ${targetValue} invalid`)
      }
    },
    allowedDisplayFields () {
      if (!this.selectedOrderData) return []

      const targetValue = this.orderDataLookup[this.selectedOrder.id].targetValue
      switch (targetValue) {
      case 'ph':
        return ['sampleId', 'ph']
      case 'humus':
        return ['sampleId', 'humusDisplay']
      case 'soilTexture':
        return ['sampleId', 'sand', 'silt', 'clay']
      default:
        throw Error(`target value ${targetValue} invalid`)
      }
    },
    preparedData () {
      if (!this.editablePointProperties) return null

      const editableData = this.editablePointProperties.map(props => {
        props.humusDisplay = this.inputMode === 'humus' ? props.humus : props.humus * 1.724
        return pick(props, this.allowedDisplayFields)
      })

      return orderBy(editableData, 'sampleId')
    }
  },
  methods: {
    loadIncompleteOrders () {
      smarterGet('/api/v2/phbb/orders/basic/{fieldId}/{harvestYear}/incompleted', {
        id: 'phbb.order-result.orders',
        inputs: {
          fieldId: () => this.$route.params.fieldId,
          harvestYear: () => this.$route.params.year
        },
        onResult: orders => {
          this.availableOrders = orders
        }
      })
    },
    update ({name, value}, sampleId) {
      if (!this.allowedDataFields.includes(name) && name !== 'humusDisplay') return

      const pointProps = this.orderDataLookup[this.selectedOrder.id].features.map(x => x.properties)
      const props = pointProps.find(props => props.sampleId === sampleId)

      if (!props) return

      if (name === 'humusDisplay') {
        if (this.inputMode === 'corg') {
          props.humus = value / 1.724
        } else {
          props.humus = value
        }
      } else {
        props[name] = value
      }
    },
    importData ({columns, data, isSemicolonSeparated}) {
      const dataLookupBySampleId = data.map(data => [data[columns.sampleId], data]).reduce(toObject, {})
      const columnsWithValue = Object.keys(columns).map(key => {
        if (columns[key]) {
          return key
        } else {
          return null
        }
      }).filter(x => x)

      Object.keys(dataLookupBySampleId).forEach(key => {
        columnsWithValue.forEach(column => {
          if (column !== 'sampleId') {
            const input = dataLookupBySampleId[key][columns[column]]
            const value = isSemicolonSeparated
              ? parseFloat(input.replace(/,/g, '.'))
              : parseFloat(input)
            this.update({name: column, value}, key)
          }
        })
      })
      this.showModal = false
    },
    async saveOrder () {
      this.validate()
      await this.$nextTick()
      if (this.state !== 'success') return

      const props = this.orderDataLookup[this.selectedOrder.id].features.map(x => x.properties)

      const measurements = props.map(x => {
        const data = pick(x, this.allowedDataFields)

        const updatable = Object.keys(data).reduce((acc, key) => {
          if (Number(x[key]) !== 0) {
            acc.push({
              sampleId: x.sampleId,
              samplePointId: x.samplePointId,
              type: key,
              value: Number(x[key])
            })
          }
          return acc
        }, [])
        return updatable
      })

      try {
        await smarterPost('/api/v2/phbb/orders', {
          id: this.selectedOrder.id,
          isCompleted: true,
          lab: this.lab,
          probingDate: this.date,
          probingMethod: this.probingMethod,
          measurements: flatten(measurements)
        }, {
          id: 'phbb.order-result.save'
        })

        this.selectedOrder = null
        this.loadIncompleteOrders()
        notifications.success(this.$i18n.translate('phbb.order-result.save.notifications.success'))
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.order-result.save.notifications.error'))
        console.error(error)
      }
    }
  },
  watch: {
    selectedOrder (order) {
      if (!order) return
      smarterGet('/api/v2/phbb/orders/{orderId}', {
        id: 'phbb.order-result.details',
        inputs: {
          orderId: () => order.id
        },
        onResult: details => {
          this.orderDataLookup = {
            [order.id]: details
          }
          this.lab = details.labName
          this.date = details.created
        }
      })
    }
  },
  created () {
    this.loadIncompleteOrders()
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 16px 0;
  width: 100%;
  border-top: 1px solid lightgrey;
}

.probing-result-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  .probing-result {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 4px;
    overflow-y: auto;

    .order-selection {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .order-select {
        width: 75%;
      }

      .import-button{
        width: 24%;
      }
    }

    .result {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;

      .result-header {
        display: flex;
        justify-content: space-between;

        .header-lab {
          width: 35%
        }

        .header-date {
          width: 35%;
        }

        .header-method {
          width: 25%;
        }
      }
    }
  }
}
</style>

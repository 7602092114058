<script>
import {get} from 'lodash'
import {min, between} from '@helpers/forms/standard-rules'
import translateUnit from 'src/js/i18n/translate-unit'
import OrderResultNumericInput from './OrderResultNumericInput'

export default {
  functional: true,
  props: {
    data: {
      type: Object
    }
  },
  render (h, context) {
    const {
      data
    } = context.props

    const CellWrapper = content => h('div', {
      class: 'cell-wrapper',
      on: {
        click: () => { context.listeners.click(data.sampleId) }
      }
    }, content)

    return Object.keys(data).map(name => {
      if (name === 'id') return null

      const value = get(data, name)
      let props = null
      if (name === 'sand' || name === 'silt' || name === 'clay' || name === 'humusDisplay') {
        props = {
          name,
          value,
          addon: translateUnit('%'),
          rule: between(0, 100)
        }
      } else if (name === 'soilGroup') {
        props = {
          name,
          value,
          rule: between(1, 6)
        }
      } else if (name === 'ph') {
        props = {
          name,
          value,
          addon: '',
          rule: between(0, 14)
        }
      } else if (name === 'targetSize') {
        props = {
          name,
          value,
          rule: min(0)
        }
      }

      return name === 'sampleId'
        ? CellWrapper([h('span', value)])
        : CellWrapper([h(OrderResultNumericInput, {
          name,
          props,
          on: {
            input: value => {
              if (context.listeners.input) {
                context.listeners.input({name, value})
              }
            }
          }
        })])
    }).filter(x => x)
  }
}
</script>

<style lang="scss" scoped>
.cell-wrapper {
  padding: 0.325em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;

  // &:nth-of-type(6n+1) {
  //   justify-content: start;
  // }

  // &:nth-of-type(8n + 1), &:nth-of-type(8n + 3), &:nth-of-type(8n + 5), &:nth-of-type(8n + 7) {
  //   background-color: rgba(#000000, 4%);
  // }
}
</style>
